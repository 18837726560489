import {createRouter, createWebHistory, RouteRecordRaw, viewDepthKey} from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/HomeViews.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/p/:id',
    name: 'post',
    component: () => import('../views/GetView.vue'),
    props: true // 启用props传递参数
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/NotFund.vue')
  },
  {
    path: '/creation',
    name: 'creation',
    component: () => import('../views/Creation.vue')
  },
  {
    path: '/userInfo/:id',
    name: 'userInfo',
    component: () => import('../views/UserInfo.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/UserInfo.vue'),
        props: true
      },
      {
        path: 'article',
        component: () => import('../views/UserInfo.vue'),
        props: true
      },
      {
        path: 'comments',
        component: () => import('../views/UserInfo.vue'),
        props: true
      },
      {
        path: 'following',
        component: () => import('../views/UserInfo.vue'),
        props: true
      },
      {
        path: 'followers',
        component: () => import('../views/UserInfo.vue'),
        props: true
      }
    ]
  },
  {
    path: '/EditorPersonageInfo',
    name: 'EditorPersonageInfo',
    component: () => import('../components/EditorPersonageInfo.vue')

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

