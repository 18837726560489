<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<style scoped>

</style>
<script>

</script>

