import {createPinia, defineStore} from "pinia";
import persistedState from 'pinia-plugin-persistedstate';
import {UserInfo} from "@/utils/blogInterface";

export const tempArticle = defineStore('tempArt', {
  persist: true,
  state: () => ({
    tempData: '',
    tempTitle: '',
    tempImageUrl: ''
  }),
  actions: {
    remove(){
      this.tempData = '';
      this.tempData = '';
      this.tempImageUrl = '';
    }
  }
})
export const useUserStore = defineStore('user', {
  persist: true,
  state: () => ({
    userInfo: null as UserInfo | null,
    token: '',
    loggedIn: false
  }),
  actions: {
    login(token: string){
      this.token = token;
      this.loggedIn = true;
    },
    logout(){
      this.userInfo = null;
      this.token = '';
      this.loggedIn = false;
      tempArticle().remove();
    }
  }
})

const pinia = createPinia()
pinia.use(persistedState)
export default pinia